import { LocationContext } from "@reach/router";
import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import ModalWrapper from "src/components/ModalWrapper";
import Seo from "src/components/Seo";
import VideoPlayer from "src/components/VideoPlayer";
import { Video, VideoRating } from "src/types";

interface ConnectStateProps {
  ratings: { [x: string]: VideoRating };
}

interface Props extends ConnectStateProps {
  "*": string;
  location: {
    state: {
      modal: boolean;
      video?: Video & { isBroadcast?: boolean };
      withRating?: boolean;
      rating?: VideoRating;
      playlistId?: string;
    };
  };
}

type State = {
  video: (Video & { isBroadcast?: boolean }) | null;
};

class VideoPage extends React.Component<Props & LocationContext, State> {
  public state = {
    video: this.props.location?.state?.video,
  };

  public async componentDidMount() {
    if (!this.props["*"]) {
      await navigate("/");
      return;
    }
  }

  public render() {
    const { state, props } = this;

    if (!this.state.video) {
      return null;
    }

    return (
      <ModalWrapper blackpage isVideo>
        <Seo
          pageTitle={state.video.name}
          pageDescription={state.video.description || ""}
        />
        <VideoPlayer
          link={state.video.link}
          videoId={state.video.videoId}
          name={state.video.name}
          description={state.video.description}
          previews={state.video.previews}
          isBroadcast={state.video.isBroadcast}
          _id={state.video._id}
          withRating={props.location.state.withRating}
          rating={props.ratings[state.video._id]}
          playlistId={props.location.state.playlistId}
        />
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  ratings: state.ratings.ratings,
});

export default connect<ConnectStateProps>(mapStateToProps)(VideoPage);
