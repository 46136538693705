import ReactVimeo from "@u-wave/react-vimeo";
import * as React from "react";
import DisplayRating from "src/components//DisplayRating";
import RatingForm from "src/components/RatingForm";
import Typography from "src/components/Typography";
import { Previews, VideoRating } from "src/types";
import styles from "./videoPlayer.module.css";

interface Props {
  _id: string;
  link: string;
  videoId: number;
  name: string;
  previews: Previews;
  description: string | void;
  isBroadcast?: boolean;
  wrapperClassName: string;
  videoOptions: {};
  rating?: VideoRating;
  playlistId?: string;
  withRating?: boolean;
}

interface State {
  isLoad: boolean;
}

class VideoPlayer extends React.Component<Props, State> {
  public state = {
    isLoad: false,
  };

  public renderPlaceholder = () => {
    if (this.state.isLoad) {
      return;
    }
    return (
      <div className={styles.renderPlaceholder}>
        <img src={this.props.previews.m.link} alt={this.props.name} />
      </div>
    );
  };

  public render() {
    const { props } = this;
    const { rating } = props;
    const isBroadcast = props.isBroadcast;
    const shouldVoteForVideo = props.withRating && !rating;
    const shouldRenderRating = props.withRating && rating;

    return (
      <div
        className={[
          this.props.wrapperClassName || "",
          styles.mainWrapper,
          isBroadcast ? styles.broadcastContainer : "",
        ].join(" ")}
      >
        <div className={styles.innerWrapper}>
          <div
            className={[
              styles.playerWrapper,
              isBroadcast ? styles.broadcastWrapper : "",
            ].join(" ")}
          >
            {this.renderPlaceholder()}
            {/* tslint:disable-next-line: no-unsafe-any */}
            <ReactVimeo
              responsive
              video={props.link}
              onReady={this.handleVideoLoad}
              className={styles.vimeoPlayer}
              showPortrait={false}
              showTitle={false}
              {...(props.videoOptions || {})}
            />
            {isBroadcast ? (
              <div className={styles.chatWrapper}>
                <iframe
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                  title={`live-chat for ${props.videoId}`}
                  src={`https://vimeo.com/live-chat/${props.videoId}`}
                />
              </div>
            ) : null}
          </div>
          {!this.props.name && !this.props.description ? null : (
            <div className={styles.textWrapper}>
              <Typography type="h4">{props.name}</Typography>
              <Typography type="text">{props.description || ""}</Typography>
            </div>
          )}
          {shouldVoteForVideo && (
            <RatingForm
              videoId={props._id}
              playlistId={this.props.playlistId}
              inline
            />
          )}
          {shouldRenderRating && rating ? (
            <div className={styles.ratingResultWrapper}>
              <DisplayRating rating={rating} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  private handleVideoLoad = () => {
    this.setState({ isLoad: true });
  };
}

export default VideoPlayer;
